import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Select, Input, Col, Row, Alert, Spin } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { checkCustomerCustomization } from 'components/CustomerCustomizationWrapper';
import { validateCpf, validateCnpj, validatePlate } from 'utils/validations';

import InputMask from 'components/Form/InputMask';
import TooltipInfo from 'components/TooltipInfo';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useFetch } from 'services/hooks';

import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';
import {
  ufsBrasil,
  sourcesDictionary,
  requiredDataUFAntecedentes,
  requiredDataUFDetran,
  disabledUFsPfAntecedentes,
} from './formUtils';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH = 'components.additionalInfo';

const AdditionalInfo = ({
  services,
  isNew,
  type,
  beforeTitle = null,
  preForm,
  showAlertInfo,
  allOptional,
  optionalCpf,
  optionalCnpj,
  requiredFieldSpecial,
  requiredInputs,
  optionalInputs,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { get: getBlockedDocuments, loading: loadingBlockedDocuments } = useFetch();

  const [dataControllerPF, setDataControllerPF] = useState({
    nadaConsta: false,
    antecedentes: false,
    rais: false,
    cpf: false,
    detran: false,
    zipCodeDetails: false,
    pfPenaltiesOnCvm: false,
    suspiciousPlaces: false,
    emailAgePrivate: false,
    denatranVehicleViolations: false,
    denatranDriverViolations: false,
    pfBackgroundCheckFederalPolice: false,
    pfAddressAuthentication: false,
    pfPhoneScore: false,
    pfBiometriaUnico: false,
    pfBiometriaVivo: false,
    pfBiometriaIdUnico: false,
    pfBiometriaSerasa: false,
    simSwapAlert: false,
    pfMbsArcaMs2: false,
    phoneAnalysis: false,
    emailAnalysis: false,
    pfFinantialData: false,
    pfOccupationData: false,
    peopleComplianceBet: false,
    vehicleViolations: false,
    driverViolations: false,
  });

  const matchPeople = useRouteMatch('/profile/people');
  const [dataControllerPJ, setDataControllerPJ] = useState({
    sintegraData: false,
    zipCodeDetails: false,
    pjCpfMei: false,
    pjPenaltiesOnCvm: false,
    suspiciousPlaces: false,
    emailAgePrivate: false,
    denatranVehicleViolations: false,
    denatranDriverViolations: false,
    vehicleViolations: false,
    driverViolations: false,
  });
  const [formUf, setFormUF] = useState('default');
  const [countrySelected, setCountrySelected] = useState(null);

  const { person } = usePerson();
  const { company } = useCompany();
  const { execution } = useExecution();

  const BeforeTitleContent = useMemo(() => {
    if (!beforeTitle) return <></>;
    return beforeTitle;
  }, [beforeTitle]);

  const isBlockedDocument = useCallback(
    async (documentNumber) => {
      const dataBlockedDocuments = await getBlockedDocuments({
        url: '/blocked-documents',
        config: {
          params: {
            _search: documentNumber,
          },
        },
      });

      const isBlocked = dataBlockedDocuments?.docs?.some((item) => item?.isBlocked);
      return isBlocked || false;
    },
    [getBlockedDocuments]
  );

  const profile = useMemo(() => {
    if (isNew) return null;

    return matchPeople ? person : company;
  }, [company, isNew, matchPeople, person]);

  const serviceHasSource = useCallback(
    (sourceToFind) =>
      services?.some(
        (service) => service?.source === sourceToFind || service === sourceToFind
      ),
    [services]
  );

  useEffect(() => {
    const nadaConsta = serviceHasSource('pf_nada_consta');
    const antecedentes = serviceHasSource('pf_antecedentes');
    const rais = serviceHasSource('pf_rais');
    const cpf = serviceHasSource('cpf');
    const detran = serviceHasSource('detran');
    const antt = serviceHasSource('antt');
    const pfPenaltiesOnCvm = serviceHasSource('pf_penalties_on_cvm');
    const pfBasicData = serviceHasSource('pf_basic_data');
    const pfCpfData = serviceHasSource('pf_cpf_data');
    const pfData = serviceHasSource('pf_data');
    const pfAddressAuthentication = serviceHasSource('pf_address_authentication');
    const pfBackgroundCheckFederalPolice = serviceHasSource(
      'pf_background_check_federal_police'
    );
    const pfSanctionsListOFAC = serviceHasSource('pf_ofac_sanctions_list');
    const pfPhoneScore = serviceHasSource('phone_trust_score');
    const pfBiometriaUnico = serviceHasSource('pf_biometria_unico');
    const pfBiometriaVivo = serviceHasSource('pf_biometria_vivo');
    const pfBiometriaIdUnico = serviceHasSource('pf_biometria_id_unico');
    const pfBiometriaSerasa = serviceHasSource('pf_biometria_serasa');
    const simSwapAlert = serviceHasSource('sim_swap_alert');
    const pfMbsArcaMs2 = serviceHasSource('pf_mbs_arca_ms2');
    const phoneAnalysis = serviceHasSource('phone_analysis');
    const emailAnalysis = serviceHasSource('email_analysis');
    const pfFinantialData = serviceHasSource('pf_finantial_data');
    const pfOccupationData = serviceHasSource('pf_occupation_data');
    const peopleComplianceBet = serviceHasSource('people_compliance_bet');

    // tanto PF quanto PJ utilizam
    const zipCodeDetails = serviceHasSource('zip_code_details');
    const suspiciousPlaces = serviceHasSource('suspicious_places');
    const emailAgePrivate = serviceHasSource('email_age_private');
    const denatranVehicleViolations = serviceHasSource('denatran_vehicle_violations');
    const denatranDriverViolations = serviceHasSource('denatran_driver_violations');
    const vehicleViolations = serviceHasSource('vehicle_violations');
    const driverViolations = serviceHasSource('driver_violations');
    // tanto PF quanto PJ utilizam

    setDataControllerPF({
      antecedentes,
      nadaConsta,
      rais,
      cpf,
      detran,
      antt,
      zipCodeDetails,
      pfPenaltiesOnCvm,
      suspiciousPlaces,
      pfBasicData,
      pfData,
      pfCpfData,
      emailAgePrivate,
      denatranVehicleViolations,
      denatranDriverViolations,
      pfAddressAuthentication,
      pfBackgroundCheckFederalPolice,
      pfSanctionsListOFAC,
      pfPhoneScore,
      pfBiometriaUnico,
      simSwapAlert,
      pfMbsArcaMs2,
      phoneAnalysis,
      emailAnalysis,
      pfFinantialData,
      pfOccupationData,
      pfBiometriaVivo,
      pfBiometriaIdUnico,
      pfBiometriaSerasa,
      peopleComplianceBet,
      vehicleViolations,
      driverViolations,
    });

    const sintegraData = serviceHasSource('pj_sintegra_data');
    const pjCpfMei = serviceHasSource('pj_cpf_mei');
    const pjPenaltiesOnCvm = serviceHasSource('pj_penalties_on_cvm');
    const pjSanctionsListOFAC = serviceHasSource('pj_ofac_sanctions_list');

    setDataControllerPJ({
      sintegraData,
      zipCodeDetails,
      pjCpfMei,
      pjPenaltiesOnCvm,
      suspiciousPlaces,
      emailAgePrivate,
      denatranVehicleViolations,
      denatranDriverViolations,
      pjSanctionsListOFAC,
      vehicleViolations,
      driverViolations,
    });
  }, [serviceHasSource]);

  const generateToolTip = useCallback(
    (servicesList, cnh, basePath) => {
      let tooltipTitle = '';

      const onlySelectedServices = services?.filter((service) =>
        servicesList?.some((source) => service?.source === source || service === source)
      );

      const allSourcesDescriptions = onlySelectedServices?.map((service) =>
        translate(`${basePath}.tooltipTitle.${sourcesDictionary[service?.source]}`)
      );

      if (onlySelectedServices?.length === 1) {
        const firstAllSourcesDescriptions = allSourcesDescriptions[0];

        tooltipTitle =
          translate(`${basePath}.tooltipTitle.unique`, {
            cnh: cnh ? 'da CNH' : '',
          }) + firstAllSourcesDescriptions;
      }

      if (onlySelectedServices?.length > 1) {
        const joinedDescriptions = translate(`${basePath}.joinedDescriptions`, {
          allSourcesDescriptions: allSourcesDescriptions.slice(0, -1).join(', '),
          lastAllSourcesDescriptions: allSourcesDescriptions.slice(-1),
        });

        tooltipTitle = translate(`${basePath}.tooltipTitle.plural`, {
          cnh: cnh ? 'da CNH' : '',
          joinedDescriptions,
        });
      }

      return tooltipTitle;
    },
    [services, translate]
  );

  const tooltipInfoSourcesRequired = useCallback(
    (servicesList, cnh) =>
      generateToolTip(servicesList, cnh, `${I18N_BASE_PATH}.tooltipInfoSourcesRequired`),
    [generateToolTip]
  );

  const tooltipInfoSourcesOptional = useCallback(
    (servicesList, cnh) =>
      generateToolTip(servicesList, cnh, `${I18N_BASE_PATH}.tooltipInfoSourcesOptional`),
    [generateToolTip]
  );

  const optionalFields = useMemo(() => {
    let arrayFields = [];

    if (dataControllerPF?.antt) {
      arrayFields = arrayFields.concat(['cnpj']);
    }
    if (dataControllerPF?.pfBasicData) {
      arrayFields = arrayFields.concat(['birthDate']);
    }
    if (dataControllerPF.pfData) {
      arrayFields = arrayFields.concat(['birthDate']);
    }

    if (dataControllerPF?.emailAgePrivate || dataControllerPJ?.emailAgePrivate) {
      arrayFields = arrayFields.concat(['cpf']);
      arrayFields = arrayFields.concat(['phoneNumber']);
      arrayFields = arrayFields.concat(['deviceId']);
    }

    if (dataControllerPF?.pfAddressAuthentication) {
      arrayFields = arrayFields.concat(['address']);
      arrayFields = arrayFields.concat(['addressNumber']);
    }

    if (dataControllerPF?.pfPhoneScore) {
      arrayFields = arrayFields.concat(['deviceId']);
      arrayFields = arrayFields.concat(['accountId']);
      arrayFields = arrayFields.concat(['accountLifecycleEvent']);
      arrayFields = arrayFields.concat(['originatingIp']);
      arrayFields = arrayFields.concat(['email']);
    }

    if (dataControllerPF?.pfMbsArcaMs2) {
      arrayFields = arrayFields.concat(['email']);
      arrayFields = arrayFields.concat(['userIp']);
      arrayFields = arrayFields.concat(['phoneNumber']);
      arrayFields = arrayFields.concat(['name']);
    }

    if (dataControllerPF?.pfBackgroundCheckFederalPolice) {
      arrayFields = arrayFields.concat(['motherName']);
    }

    if (dataControllerPF?.pfFinantialData) {
      arrayFields = arrayFields.concat(['birthDate']);
    }

    if (dataControllerPF?.pfOccupationData) {
      arrayFields = arrayFields.concat(['birthDate']);
    }

    if (dataControllerPF?.peopleComplianceBet) {
      arrayFields = arrayFields.concat(['birthDate']);
    }

    return arrayFields;
  }, [dataControllerPF, dataControllerPJ]);

  const requiredFields = useMemo(() => {
    let arrayFields = [];

    if (dataControllerPF?.pfBackgroundCheckFederalPolice) {
      arrayFields = arrayFields.concat(['name']);
    }

    if (dataControllerPF?.nadaConsta) {
      arrayFields = arrayFields.concat(['issueState']);
    }
    if (dataControllerPF?.antecedentes) {
      arrayFields = arrayFields.concat([
        ...requiredDataUFAntecedentes[formUf],
        'issueState',
      ]);
    }
    if (dataControllerPF?.rais) {
      arrayFields = arrayFields.concat(['nit']);
    }
    if (dataControllerPF?.cpf) {
      arrayFields = arrayFields.concat(['birthDate']);
    }
    if (dataControllerPF?.detran) {
      arrayFields = arrayFields.concat([
        ...requiredDataUFDetran.default,
        'cnhPlaceOfIssue',
      ]);
    }
    if (dataControllerPF?.pfSanctionsListOFAC || dataControllerPJ?.pJSanctionsListOFAC) {
      arrayFields = arrayFields.concat(['name']);
    }
    if (dataControllerPF?.pfSanctionsListOFAC || dataControllerPJ?.pjSanctionsListOFAC) {
      arrayFields = arrayFields.concat(['name']);
    }
    if (dataControllerPF?.pfSanctionsListOFAC || dataControllerPJ?.pjSanctionsListOFAC) {
      arrayFields = arrayFields.concat(['name']);
    }
    if (dataControllerPJ?.sintegraData) {
      arrayFields = arrayFields.concat(['uf']);
    }

    if (
      dataControllerPF?.antt ||
      dataControllerPF?.denatranVehicleViolations ||
      dataControllerPF?.vehicleViolations
    ) {
      arrayFields = arrayFields.concat(['plate']);
    }

    if (dataControllerPF?.driverViolations) {
      arrayFields = arrayFields.concat(['cnhRegistrationNumber']);
    }

    if (dataControllerPF?.zipCodeDetails || dataControllerPJ?.zipCodeDetails) {
      arrayFields = arrayFields.concat(['cep']);
    }

    if (dataControllerPF?.emailAgePrivate || dataControllerPJ?.emailAgePrivate) {
      arrayFields = arrayFields.concat(['email']);
      arrayFields = arrayFields.concat(['userIp']);
    }

    if (dataControllerPJ?.pjCpfMei) {
      arrayFields = arrayFields.concat(['cpf']);
      arrayFields = arrayFields.concat(['birthDate']);
    }

    if (dataControllerPJ.denatranDriverViolations) {
      arrayFields = arrayFields.concat(['cpf']);
    }

    if (dataControllerPF?.pfPenaltiesOnCvm || dataControllerPJ?.pjPenaltiesOnCvm) {
      arrayFields = arrayFields.concat(['name']);
    }

    if (dataControllerPF?.suspiciousPlaces || dataControllerPJ?.suspiciousPlaces) {
      arrayFields = arrayFields.concat(['latitude']);
      arrayFields = arrayFields.concat(['longitude']);
      arrayFields = arrayFields.concat(['accuracy']);
    }

    if (
      dataControllerPF?.pfBiometriaUnico ||
      dataControllerPF?.pfBiometriaVivo ||
      dataControllerPF?.pfBiometriaIdUnico ||
      dataControllerPF?.pfBiometriaSerasa
    ) {
      arrayFields = arrayFields.concat(['name']);
    }

    if (dataControllerPF?.pfAddressAuthentication) {
      arrayFields = arrayFields.concat(['phoneNumber']);
      arrayFields = arrayFields.concat(['cep']);
    }

    if (dataControllerPF?.pfPhoneScore) {
      arrayFields = arrayFields.concat(['phoneNumber']);
    }

    if (dataControllerPF?.simSwapAlert) {
      arrayFields = arrayFields.concat(['phoneNumber']);
    }

    if (dataControllerPF?.phoneAnalysis) {
      arrayFields = arrayFields.concat(['phoneNumber']);
    }

    if (dataControllerPF?.emailAnalysis) {
      arrayFields = arrayFields.concat(['email']);
    }

    return arrayFields;
  }, [dataControllerPF, dataControllerPJ, formUf]);

  return (
    <div>
      {showAlertInfo && (
        <Alert
          className="alert-info-form"
          message={translate(`${I18N_BASE_PATH}.optionalFieldsAlert.text`)}
          type="info"
          showIcon
        />
      )}

      {BeforeTitleContent}
      <Row>
        {(profile?.cpf || type === 'PF') && (
          <Col
            span={
              requiredFields.some((ai) =>
                ['cnhPlaceOfIssue', 'issueState', 'uf'].includes(ai)
              )
                ? 16
                : 24
            }
          >
            <Form.Item
              rules={[
                {
                  required: (!allOptional && !optionalCpf) || requiredFieldSpecial,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
                () => ({
                  validator(rule, cpf) {
                    const validatorCpf = validateCpf(cpf);
                    if (!validatorCpf && cpf.length) {
                      return Promise.reject(
                        new Error(
                          translate(
                            `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.invalidCpf`
                          )
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  async validator(rule, cpf) {
                    const cpfNumberOnly = cpf?.replace(/\D/g, '');

                    if (cpfNumberOnly && cpfNumberOnly.length === 11) {
                      const isSkyCustomer = checkCustomerCustomization(user, 'SKY');

                      if (isSkyCustomer) {
                        const isBlocked = await isBlockedDocument(cpfNumberOnly);
                        if (isBlocked) {
                          return Promise.reject(
                            new Error(
                              translate(
                                `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.blockedDocument`
                              )
                            )
                          );
                        }
                        return Promise.resolve();
                      }
                    }
                  },
                }),
              ]}
              name="cpf"
              initialValue={profile?.cpf ? toMaskedCpf(profile?.cpf) : ''}
              label={
                <span>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.cpf`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => translate(`${I18N_BASE_PATH}.cpf.title`)}
                    size={12}
                  />
                </span>
              }
            >
              <InputMask
                disabled={!isNew || preForm || loadingBlockedDocuments}
                prefix={<i className="caf-ic_person" />}
                suffix={
                  loadingBlockedDocuments && <Spin className="mrg-top-15" size="small" />
                }
                mask="999.999.999-99"
              />
            </Form.Item>
          </Col>
        )}
        {(profile?.cpf || execution?.parameters?.cpf || type === 'PF') &&
          requiredFields.some((ai) =>
            ['cnhPlaceOfIssue', 'issueState', 'uf'].includes(ai)
          ) && (
            <Col span={8}>
              <Form.Item
                label={
                  <div>
                    {translate(
                      `${I18N_BASE_PATH}.additionalInfoBasedOnProfileTemplate.uf.text`
                    )}
                    <TooltipInfo
                      color="#2f80ed"
                      placement="top"
                      title={() =>
                        tooltipInfoSourcesRequired([
                          'pf_antecedentes',
                          'pf_nada_consta',
                          'detran',
                        ])
                      }
                      size={12}
                    />
                  </div>
                }
                initialValue={
                  profile?.ocr?.rg?.rgIssueState ||
                  profile?.ocr?.cnh?.cnhPlaceOfIssue ||
                  execution?.parameters?.rgIssueState ||
                  execution?.parameters?.cnhPlaceOfIssue ||
                  execution?.sections?.ocr?.rgIssueState ||
                  execution?.sections?.ocr?.cnhPlaceOfIssue ||
                  ''
                }
                rules={[
                  {
                    required: !allOptional,
                    message: translate(
                      `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                    ),
                  },
                ]}
                name="uf"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onSelect={(value) => setFormUF(value)}
                >
                  {ufsBrasil.map((uf) => (
                    <Option
                      key={uf}
                      value={uf}
                      disabled={
                        disabledUFsPfAntecedentes.includes(uf) &&
                        dataControllerPF.antecedentes
                      }
                      title={
                        disabledUFsPfAntecedentes.includes(uf)
                          ? translate(`${I18N_BASE_PATH}.disabledUF`)
                          : uf
                      }
                    >
                      {uf}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

        {(profile?.cnpj ||
          type === 'PJ' ||
          execution?.parameters?.cnpj ||
          (type === 'PF' && optionalFields.includes('cnpj'))) && (
          <Col span={requiredFields?.includes('uf') ? 16 : 24}>
            <Form.Item
              rules={[
                {
                  required:
                    !allOptional && !optionalCnpj && !optionalFields.includes('cnpj'),
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
                () => ({
                  validator(rule, cnpj) {
                    if ((!cnpj && optionalFields.includes('cnpj')) || allOptional) {
                      return Promise.resolve();
                    }

                    const validatorCnpj = validateCnpj(cnpj);

                    if (!validatorCnpj && cnpj.length) {
                      return Promise.reject(
                        new Error(
                          translate(
                            `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.invalidCnpj`
                          )
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
                () => ({
                  async validator(rule, cnpj) {
                    const cnpjNumberOnly = cnpj?.replace(/\D/g, '');

                    if (cnpjNumberOnly && cnpjNumberOnly.length === 14) {
                      const isSkyCustomer = checkCustomerCustomization(user, 'SKY');

                      if (isSkyCustomer) {
                        const isBlocked = await isBlockedDocument(cnpjNumberOnly);
                        if (isBlocked) {
                          return Promise.reject(
                            new Error(
                              translate(
                                `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.blockedDocument`
                              )
                            )
                          );
                        }
                        return Promise.resolve();
                      }
                    }
                  },
                }),
              ]}
              name="cnpj"
              initialValue={
                profile?.cnpj || execution?.parameters?.cnpj
                  ? toMaskedCnpj(profile?.cnpj || execution?.parameters?.cnpj)
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.cnpj`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      type === 'PF' && !requiredFields.includes('cnpj')
                        ? tooltipInfoSourcesOptional(['antt'])
                        : translate(`${I18N_BASE_PATH}.cnpj.title`)
                    }
                    size={12}
                  />
                </div>
              }
            >
              <InputMask
                disabled={!isNew || preForm || loadingBlockedDocuments}
                prefix={<i className="caf-ic_company" />}
                mask="99.999.999/9999-99"
                suffix={
                  loadingBlockedDocuments && <Spin className="mrg-top-15" size="small" />
                }
              />
            </Form.Item>
          </Col>
        )}

        {requiredFields?.includes('uf') && (
          <Col span={8}>
            <Form.Item
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.ufCnpj`)}

                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesRequired(['pj_sintegra_data'])}
                    size={12}
                  />
                </div>
              }
              initialValue={
                profile?.basicData?.uf ||
                execution?.parameters?.uf ||
                execution?.sections?.ocr?.uf ||
                ''
              }
              rules={[
                {
                  required: !allOptional,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
              ]}
              name="uf"
            >
              <Select
                showSearch
                optionFilterProp="children"
                onSelect={(value) => setFormUF(value)}
              >
                {ufsBrasil.map((uf) => (
                  <Option key={uf} value={uf}>
                    {uf}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {requiredFields?.includes('nit') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
              ]}
              name="nit"
              initialValue={
                !preForm && (profile?.basicData?.nit || execution?.parameters?.nit)
                  ? profile?.basicData?.nit || execution?.parameters?.nit
                  : undefined
              }
              label={
                <div>
                  {translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.nit`,
                    'NIT (PIS/PASEP)'
                  )}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesRequired(['pf_rais'])}
                    size={12}
                  />
                </div>
              }
            >
              <InputMask mask="99999999999" disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {(!requiredFields.some((ai) =>
          ['cnhPlaceOfIssue', 'issueState', 'uf'].includes(ai)
        ) ||
          formUf !== 'default') && (
          <>
            {requiredFields?.includes('name') && (
              <Col span={24} className="custom-label-content">
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  initialValue={
                    !preForm && (profile?.basicData?.name || execution?.parameters?.name)
                      ? profile?.basicData?.name || execution?.parameters?.name
                      : undefined
                  }
                  name={preForm ? 'fullName' : 'name'}
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.fullName.text`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired([
                            'pf_antecedentes',
                            'detran',
                            'pj_penalties_on_cvm',
                            'pf_penalties_on_cvm',
                            'pf_biometria_unico',
                          ])
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} style={{ textTransform: 'uppercase' }} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('motherName') && (
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  initialValue={
                    !preForm &&
                    (profile?.basicData?.motherName || execution?.parameters?.motherName)
                      ? profile?.basicData?.motherName ||
                        execution?.parameters?.motherName
                      : undefined
                  }
                  name="motherName"
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.motherName.text`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['pf_antecedentes', 'detran'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} style={{ textTransform: 'uppercase' }} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('fatherName') && (
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="fatherName"
                  initialValue={
                    !preForm &&
                    (profile?.basicData?.fatherName || execution?.parameters?.fatherName)
                      ? profile?.basicData?.fatherName ||
                        execution?.parameters?.fatherName
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.fatherName.text`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['pf_antecedentes'])}
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} style={{ textTransform: 'uppercase' }} />
                </Form.Item>
              </Col>
            )}
            {requiredFields?.includes('cpf') && (
              <Col span={16}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="cpf"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.ocr?.cpf || execution?.parameters?.cpf)
                      ? execution?.sections?.ocr?.cpf || execution?.parameters?.cpf
                      : undefined
                  }
                  label={
                    <div>
                      {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.cpf`)}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(
                            ['pj_cpf_mei', 'denatran_driver_violations'],
                            false
                          )
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('birthDate') && (
              <Col span={8} className="custom-label-content">
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="birthDate"
                  initialValue={
                    !preForm &&
                    (profile?.basicData?.birthDate ||
                      execution?.parameters?.birthDate ||
                      execution?.sections?.ocr?.birthDate)
                      ? profile?.basicData?.birthDate ||
                        execution?.parameters?.birthDate ||
                        execution?.sections?.ocr?.birthDate
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.birthDate.text`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired([
                            'cpf',
                            'detran',
                            'pf_antecedentes',
                            'pj_cpf_mei',
                          ])
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <InputMask disabled={preForm} mask="99/99/9999" />
                </Form.Item>
              </Col>
            )}
            {optionalFields?.includes('birthDate') && (
              <Col span={8} className="custom-label-content">
                <Form.Item
                  name="birthDate"
                  initialValue={
                    !preForm &&
                    (profile?.basicData?.birthDate ||
                      execution?.parameters?.birthDate ||
                      execution?.sections?.ocr?.birthDate)
                      ? profile?.basicData?.birthDate ||
                        execution?.parameters?.birthDate ||
                        execution?.sections?.ocr?.birthDate
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.birthDate.text`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => {
                          tooltipInfoSourcesOptional([
                            'people_compliance_bet',
                            'pf_basic_data',
                            'pf_data',
                          ]);

                          if (
                            dataControllerPF?.pfFinantialData ||
                            dataControllerPF?.pfOccupationData ||
                            dataControllerPF?.peopleComplianceBet
                          ) {
                            return translate(
                              `${I18N_BASE_PATH}.additionalInfoBox.inputs.birthDate.aditionalTooltip`
                            );
                          }
                        }}
                        size={12}
                      />
                    </div>
                  }
                >
                  <InputMask disabled={preForm} mask="99/99/9999" />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('rg') && (
              <Col span={8} className="custom-label-content">
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="rg"
                  initialValue={
                    !preForm &&
                    (profile?.basicData?.rg ||
                      execution?.parameters?.rg ||
                      execution?.sections?.ocr?.rg)
                      ? profile?.basicData?.rg ||
                        execution?.parameters?.rg ||
                        execution?.sections?.ocr?.rg
                      : undefined
                  }
                  label={
                    <div>
                      {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.rg`)}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['pf_antecedentes', 'rg', 'detran'])
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} style={{ textTransform: 'uppercase' }} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('issueDate') && (
              <Col span={8} className="custom-label-content">
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="issueDate"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.rg?.issueDate || execution?.parameters?.issueDate)
                      ? profile?.ocr?.rg?.issueDate || execution?.parameters?.issueDate
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.rgIssueDate`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['pf_antecedentes'])}
                        size={12}
                      />
                    </div>
                  }
                >
                  <InputMask mask="99/99/9999" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('rgIssuingAuthority') && (
              <Col span={8} className="custom-label-content">
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="rgIssuingAuthority"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.rg?.rgIssuingAuthority ||
                      execution?.parameters?.rgIssuingAuthority ||
                      execution?.sections?.ocr?.rgIssuingAuthority)
                      ? profile?.ocr?.rg?.rgIssuingAuthority ||
                        execution?.parameters?.rgIssuingAuthority ||
                        execution?.sections?.ocr?.rgIssuingAuthority
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.rgIssuingAuthority`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['pf_antecedentes'])}
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input style={{ textTransform: 'uppercase' }} disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('cnhRegistrationNumber') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="cnhRegistrationNumber"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.cnh?.cnhRegistrationNumber ||
                      execution?.sections?.ocr?.cnhRegistrationNumber ||
                      execution?.parameters?.cnhRegistrationNumber)
                      ? profile?.ocr?.cnh?.cnhRegistrationNumber ||
                        execution?.parameters?.cnhRegistrationNumber ||
                        execution?.sections?.ocr?.cnhRegistrationNumber
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.cnhRegistrationNumber`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['detran'], true)}
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('cnhRenachNumber') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="cnhRenachNumber"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.cnh?.cnhRenachNumber ||
                      execution?.sections?.ocr?.cnhRenachNumber ||
                      execution?.parameters?.cnhRenachNumber)
                      ? profile?.ocr?.cnh?.cnhRenachNumber ||
                        execution?.sections?.ocr?.cnhRenachNumber ||
                        execution?.parameters?.cnhRenachNumber
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.cnhRenachNumber`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['detran'], true)}
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('cnhMirrorNumber') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="cnhMirrorNumber"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.cnh?.cnhMirrorNumber ||
                      execution?.sections?.ocr?.cnhMirrorNumber ||
                      execution?.parameters?.cnhMirrorNumber)
                      ? profile?.ocr?.cnh?.cnhMirrorNumber ||
                        execution?.sections?.ocr?.cnhMirrorNumber ||
                        execution?.parameters?.cnhMirrorNumber
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.cnhMirrorNumber`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['detran'], true)}
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('plate') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                    () => ({
                      validator(plate) {
                        const validatorPlate = validatePlate(plate);
                        if (!validatorPlate && plate.length) {
                          return Promise.reject(
                            new Error(
                              translate(
                                `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.invalidPlate`
                              )
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  name="plate"
                  initialValue={
                    !preForm
                      ? execution?.sections?.ocr?.plate || execution?.parameters?.plate
                      : undefined
                  }
                  label={
                    <div>
                      {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.plate`)}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired([
                            'antt',
                            'denatran_vehicle_violations',
                          ])
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <InputMask
                    mask="aaa9*99"
                    disabled={preForm}
                    style={{ textTransform: 'uppercase' }}
                  />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('cnhFirstDriveLicenseDate') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="cnhFirstDriveLicenseDate"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.cnh?.cnhFirstDriveLicenseDate ||
                      execution?.sections?.ocr?.cnhFirstDriveLicenseDate ||
                      execution?.parameters?.cnhFirstDriveLicenseDate)
                      ? profile?.ocr?.cnh?.cnhFirstDriveLicenseDate ||
                        execution?.sections?.ocr?.cnhFirstDriveLicenseDate ||
                        execution?.parameters?.cnhFirstDriveLicenseDate
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.cnhFirstDriveLicenseDate`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['detran'], true)}
                        size={12}
                      />
                    </div>
                  }
                >
                  <InputMask mask="99/99/9999" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('cnhExpirationDate') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="cnhExpirationDate"
                  initialValue={
                    !preForm &&
                    (profile?.ocr?.cnh?.cnhExpirationDate ||
                      execution?.sections?.ocr?.cnhExpirationDate ||
                      execution?.parameters?.cnhExpirationDate)
                      ? profile?.ocr?.cnh?.cnhExpirationDate ||
                        execution?.sections?.ocr?.cnhExpirationDate ||
                        execution?.parameters?.cnhExpirationDate
                      : undefined
                  }
                  label={
                    <div>
                      {translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.cnhExpirationDate`
                      )}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() => tooltipInfoSourcesRequired(['detran'], true)}
                        size={12}
                      />
                    </div>
                  }
                >
                  <InputMask mask="99/99/9999" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('latitude') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="latitude"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.suspiciousPlaces?.latitude ||
                      execution?.parameters?.latitude)
                      ? execution?.sections?.suspiciousPlaces?.latitude ||
                        execution?.parameters?.latitude
                      : undefined
                  }
                  label={
                    <div>
                      {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.latitude`)}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['suspicious_places'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input type="number" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('longitude') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="longitude"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.suspiciousPlaces?.longitude ||
                      execution?.parameters?.longitude)
                      ? execution?.sections?.suspiciousPlaces?.longitude ||
                        execution?.parameters?.longitude
                      : undefined
                  }
                  label={
                    <div>
                      {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.longitude`)}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['suspicious_places'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input type="number" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('accuracy') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="accuracy"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.suspiciousPlaces?.accuracy ||
                      execution?.parameters?.accuracy)
                      ? execution?.sections?.suspiciousPlaces?.accuracy ||
                        execution?.parameters?.accuracy
                      : undefined
                  }
                  label={
                    <div>
                      {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.accuracy`)}
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['suspicious_places'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input type="number" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('latitude') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="latitude"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.suspiciousPlaces?.latitude ||
                      execution?.parameters?.latitude)
                      ? execution?.sections?.suspiciousPlaces?.latitude ||
                        execution?.parameters?.latitude
                      : undefined
                  }
                  label={
                    <div>
                      LATITUDE
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['suspicious_places'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input type="number" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('longitude') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="longitude"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.suspiciousPlaces?.longitude ||
                      execution?.parameters?.longitude)
                      ? execution?.sections?.suspiciousPlaces?.longitude ||
                        execution?.parameters?.longitude
                      : undefined
                  }
                  label={
                    <div>
                      LATITUDE
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['suspicious_places'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input type="number" disabled={preForm} />
                </Form.Item>
              </Col>
            )}

            {requiredFields?.includes('accuracy') && (
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: !allOptional,
                      message: translate(
                        `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                      ),
                    },
                  ]}
                  name="accuracy"
                  initialValue={
                    !preForm &&
                    (execution?.sections?.suspiciousPlaces?.accuracy ||
                      execution?.parameters?.accuracy)
                      ? execution?.sections?.suspiciousPlaces?.accuracy ||
                        execution?.parameters?.accuracy
                      : undefined
                  }
                  label={
                    <div>
                      ACURACIDADE
                      <TooltipInfo
                        color="#2f80ed"
                        placement="top"
                        title={() =>
                          tooltipInfoSourcesRequired(['suspicious_places'], false)
                        }
                        size={12}
                      />
                    </div>
                  }
                >
                  <Input type="number" disabled={preForm} />
                </Form.Item>
              </Col>
            )}
          </>
        )}

        {requiredFields?.includes('cep') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: !allOptional || dataControllerPF?.pfAddressAuthentication,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
              ]}
              name="cep"
              initialValue={
                !preForm && (execution?.sections?.ocr?.cep || execution?.parameters?.cep)
                  ? execution?.sections?.ocr?.cep || execution?.parameters?.cep
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.cep`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesRequired(
                        ['zip_code_details', 'pf_address_authentication'],
                        false
                      )
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {requiredFields?.includes('email') && (
          <Col span={dataControllerPF?.emailAnalysis ? 24 : 8}>
            <Form.Item
              rules={[
                {
                  required: !allOptional,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
              ]}
              name="email"
              initialValue={
                !preForm && execution?.parameters?.email
                  ? execution?.parameters?.email
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.email`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesRequired(
                        ['email_age_private', 'email_analysis'],
                        false
                      )
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} type="email" />
            </Form.Item>
          </Col>
        )}

        {requiredFields?.includes('userIp') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: !allOptional,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
              ]}
              name="userIp"
              initialValue={
                !preForm && execution?.parameters?.userIp
                  ? execution?.parameters?.userIp
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.userIp`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesRequired(['email_age_private'], false)}
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {requiredFields?.includes('phoneNumber') && (
          <Col
            span={
              dataControllerPF?.simSwapAlert || dataControllerPF?.phoneAnalysis ? 24 : 8
            }
          >
            <Form.Item
              rules={[
                {
                  required:
                    !allOptional ||
                    dataControllerPF?.pfAddressAuthentication ||
                    dataControllerPF?.simSwapAlert ||
                    dataControllerPF?.pfPhoneScore ||
                    dataControllerPF?.phoneAnalysis,
                  message: translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.validateMessages.required`
                  ),
                },
              ]}
              name="phoneNumber"
              initialValue={
                !preForm && execution?.parameters?.phoneNumber
                  ? execution?.parameters?.phoneNumber
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.phoneNumber`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesRequired(
                        [
                          'pf_address_authentication',
                          'phone_trust_score',
                          'sim_swap_alert',
                          'phone_analysis',
                        ],
                        false
                      )
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {optionalFields?.includes('address') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="address"
              initialValue={
                !preForm && execution?.parameters?.address
                  ? execution?.parameters?.address
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.address`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesOptional(['pf_address_authentication'], false)
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}
        {optionalFields?.includes('addressNumber') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="addressNumber"
              initialValue={
                !preForm && execution?.parameters?.addressNumber
                  ? execution?.parameters?.addressNumber
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.addressNumber`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesOptional(['pf_address_authentication'], false)
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}
        {optionalFields?.includes('name') && (
          <Col span={24} className="custom-label-content">
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              initialValue={
                !preForm && (profile?.basicData?.name || execution?.parameters?.name)
                  ? profile?.basicData?.name || execution?.parameters?.name
                  : undefined
              }
              name={preForm ? 'fullName' : 'name'}
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.fullName.text`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesOptional(['pf_mbs_arca_ms2'])}
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} style={{ textTransform: 'uppercase' }} />
            </Form.Item>
          </Col>
        )}

        {optionalFields?.includes('motherName') && (
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              initialValue={
                !preForm &&
                (profile?.basicData?.motherName || execution?.parameters?.motherName)
                  ? profile?.basicData?.motherName || execution?.parameters?.motherName
                  : undefined
              }
              name="motherName"
              label={
                <div>
                  {translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.motherName.text`
                  )}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesRequired(['pf_antecedentes', 'detran'], false)
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} style={{ textTransform: 'uppercase' }} />
            </Form.Item>
          </Col>
        )}

        {optionalFields?.includes('phoneNumber') && (
          <Col span={dataControllerPF?.pfMbsArcaMs2 ? 12 : 8}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="phoneNumber"
              initialValue={
                !preForm && execution?.parameters?.userIp
                  ? execution?.parameters?.userIp
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.phoneNumber`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesOptional(
                        ['email_age_private', 'pf_mbs_arca_ms2'],
                        false
                      )
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {optionalFields?.includes('userIp') && (
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="userIp"
              initialValue={
                !preForm && execution?.parameters?.userIp
                  ? execution?.parameters?.userIp
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.userIp`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesOptional(['pf_mbs_arca_ms2'], false)}
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {optionalFields?.includes('deviceId') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="deviceId"
              initialValue={
                !preForm && execution?.parameters?.userIp
                  ? execution?.parameters?.userIp
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.deviceId`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesOptional(
                        ['email_age_private', 'phone_trust_score'],
                        false
                      )
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}
        {optionalFields?.includes('accountId') && (
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="accountId"
              initialValue={
                !preForm && execution?.parameters?.accountId
                  ? execution?.parameters?.accountId
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.accountId`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesOptional(['phone_trust_score'], false)}
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}
        {optionalFields?.includes('email') && (
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="email"
              initialValue={
                !preForm && execution?.parameters?.email
                  ? execution?.parameters?.email
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.email`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() =>
                      tooltipInfoSourcesOptional(
                        ['phone_trust_score', 'pf_mbs_arca_ms2'],
                        false
                      )
                    }
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {optionalFields?.includes('originatingIp') && (
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="originatingIp"
              initialValue={
                !preForm && execution?.parameters?.originatingIp
                  ? execution?.parameters?.originatingIp
                  : undefined
              }
              label={
                <div>
                  {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.originatingIp`)}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesOptional(['phone_trust_score'], false)}
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}
        {optionalFields?.includes('accountLifecycleEvent') && (
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              name="accountLifecycleEvent"
              initialValue={
                !preForm && execution?.parameters?.accountLifecycleEvent
                  ? execution?.parameters?.accountLifecycleEvent
                  : undefined
              }
              label={
                <div>
                  {translate(
                    `${I18N_BASE_PATH}.additionalInfoBox.inputs.accountLifecycleEvent`
                  )}
                  <TooltipInfo
                    color="#2f80ed"
                    placement="top"
                    title={() => tooltipInfoSourcesOptional(['phone_trust_score'], false)}
                    size={12}
                  />
                </div>
              }
            >
              <Input disabled={preForm} />
            </Form.Item>
          </Col>
        )}

        {/* WorkflowsInputs */}
        {hasFeatureFlag('isWorkflowBuilder', user) && (
          <>
            <Col span={24} className="custom-label-content">
              <Form.Item
                name="country"
                label={<div>Country</div>}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onSelect={(value) => setCountrySelected(value)}
                  placeholder="Select country"
                >
                  <Option value="US" key="US">
                    United States
                  </Option>
                  <Option value="GB" key="GB">
                    United Kingdom
                  </Option>
                  <Option value="MX" key="MX">
                    Mexico
                  </Option>
                  <Option value="IE" key="IE">
                    Ireland
                  </Option>
                  {/* <Option value="BR" key="BR">
                    Brazil
                  </Option> */}
                  <Option value="CA" key="CA">
                    Canada
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Obrigatório em todas */}
            <Col span={24} className="custom-label-content">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label={
                  <div>
                    {translate(
                      `${I18N_BASE_PATH}.additionalInfoBox.inputs.fullName.text`
                    )}
                  </div>
                }
              >
                <Input style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>

            {/* CA, GB, US */}
            <Col span={24} className="custom-label-content">
              <Form.Item
                name="address"
                rules={[
                  {
                    required: ['CA', 'GB', 'US'].includes(countrySelected),
                  },
                ]}
                label={
                  <div>
                    {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.address`)}
                  </div>
                }
              >
                <Input />
              </Form.Item>
            </Col>

            {/* MX */}
            <Col span={24} className="custom-label-content">
              <Form.Item
                name="nationalId"
                rules={[
                  {
                    required: ['MX'].includes(countrySelected),
                  },
                ]}
                label={<div>National ID</div>}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* IE, MX,  */}
            <Col span={24} className="custom-label-content">
              <Form.Item
                name="birthDate"
                rules={[
                  {
                    required: ['IE', 'MX'].includes(countrySelected),
                  },
                ]}
                label={<div>Birth date</div>}
              >
                <InputMask mask="99/99/9999" />
              </Form.Item>
            </Col>

            {/* CA */}
            <Col span={24} className="custom-label-content">
              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: ['CA'].includes(countrySelected),
                  },
                ]}
                label={
                  <div>
                    {translate(`${I18N_BASE_PATH}.additionalInfoBox.inputs.phoneNumber`)}
                  </div>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

AdditionalInfo.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isNew: PropTypes.bool,
  type: PropTypes.string,
  beforeTitle: PropTypes.element,
  preForm: PropTypes.bool,
  showAlertInfo: PropTypes.bool,
  optionalCpf: PropTypes.bool,
  requiredFieldSpecial: PropTypes.bool,
  optionalCnpj: PropTypes.bool,
  allOptional: PropTypes.bool,
  requiredInputs: PropTypes.string,
  optionalInputs: PropTypes.string,
};

AdditionalInfo.defaultProps = {
  isNew: false,
  type: undefined,
  beforeTitle: undefined,
  preForm: false,
  showAlertInfo: false,
  optionalCpf: false,
  requiredFieldSpecial: false,
  optionalCnpj: false,
  allOptional: true,
  requiredInputs: '',
  optionalInputs: '',
};

export default AdditionalInfo;
